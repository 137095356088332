<template>
  <div>
    <x-table
      tableType="card"
      :options="options"
      :fixed="false"
      :dataPreHandle="dataPreHandle"
      @search="searchHandle"
      @rowAdd="addHandle"
      @rowEdit="rowEdit"
      @rowPublish="rowPublish"
      @rowDownload="rowDownload"
      @rowDelete="rowDelete"
      @rowDisable="rowDisable"
      @beforeShow="beforeShow">
      <template slot="form-photoUrl" slot-scope="{row}">
        <!-- <b-avatar
          class="mb-1"
          size="70px"
          :src="photoUrl"
        /> -->
        <div @click="handlePictureCardPreview(row)">
          <b-avatar
            :class="photoUrl ? 'el-upload-list__item-thumbnail upload-cx' : 'el-upload-list__item-thumbnail'"
            style="width: 100px;height: 100px; font-size: 26px"
            :src="photoUrl" alt=""
            text="PM"
            variant="light-primary"
          >
          </b-avatar>
        </div>
        <el-upload
          class="upload-demo mb-1 mt-1 ml-1"
          :action="action"
          :headers="headers"
          :file-list="fileList"
          accept=".jpg, .png, .gif"
          :show-file-list="false"
          :limit="1"
          :on-exceed="onExceed"
          :on-success="(response) => uploadSuccess(response, row)">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" size="sm">点击上传</b-button>
        </el-upload>
        <b-modal
          title-class="x-text-bold"
          body-class="pl-4 pr-4"
          cancel-variant="flat-secondary"
          ok-title="删除"
          cancel-title="取消"
          centered
          title="预览"
          :visible.sync="dialogVisible"
          @hidden="restModal"
          @ok="handleRemove(row)">
          <img width="100%" :src="dialogImageUrl" alt="">
        </b-modal>
      </template>
    </x-table>
  </div>

</template>

<script>
import { BAvatar, BButton, BModal } from 'bootstrap-vue'
import {
  query, unDeploy, deploy, remove, count, add, edit,
} from '@/api/device-manage/product-list'
import XTable from '@core/components/cx/table/XTable.vue'
import { getToken } from '@/@core/auth/token'

export default {
  components: {
    XTable,
    // ProductForm,
    BAvatar,
    BButton,
    BModal,
  },
  data() {
    return {
      // modal查看照片地址
      dialogImageUrl: undefined,
      // 编辑对象
      editRow: {},
      fileList: [],
      dialogVisible: false,
      // 图片地址
      photoUrl: undefined,
      // 上传请求地址
      action: this.$xapi.UPLOAD,
      // 上传请求头部信息
      headers: {
        'X-Access-Token': getToken(),
      },
      options: {
        cardProps: {
          icon: 'photoUrl',
          name: 'name',
        },
        formType: 'sidebar',
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        actions: [{ action: this.$x.biz.FormType.VIEW, name: '查看', icon: 'EyeIcon', url: '/device-center/products/view/{id}' },
          { action: this.$x.biz.FormType.EDIT, name: '编辑', icon: 'EditIcon' },
          { action: 'download', name: '下载', icon: 'DownloadIcon' },
          { action: 'publish', name: '发布', icon: 'UploadCloudIcon' },
          { action: 'disable', name: '禁用', icon: 'SlashIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        actionFilter: (action, data) => {
          if (data.state === 1) {
            return action !== 'publish' && action !== 'delete'
          }
          return action !== 'disable'
        },
        collapseExpandAll: true,
        columns: [{
          label: '图片',
          prop: 'photoUrl',
          type: 'image',
          labelShow: false,
          searchShow: false,
        }, {
          label: '产品ID',
          prop: 'id',
          editDisable: true,
          sortable: true,
          rules: {
            rule: 'required',
          },
        }, {
          label: '产品名称',
          prop: 'name',
          sortable: true,
          rules: {
            rule: 'required',
          },
        }, {
          label: '设备数量',
          prop: 'count',
          linkUrl: '/device-center/device-manager/devices?productId={id}',
          sortable: true,
          editShow: false,
          addShow: false,
          searchShow: false,
          rowCls: 'text-center',
          rules: {
            rule: 'required',
          },
        }, {
          label: '发布状态',
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          addShow: false,
          dictData: [{ label: '已发布', value: 1 }, { label: '未发布', value: 0 }],
          selectVariant: prop => {
            if (prop === 1) {
              return 'text-success'
            }
            return 'text-danger'
          },
          sortable: true,
          editShow: false,
        }, {
          label: '产品品类',
          prop: 'classifiedId',
          type: 'select',
          dictUrl: '/device/category?paging=false',
          props: { label: 'name', value: 'id' },
          sortable: true,
          rules: {
            rule: 'required',
          },
        }, {
          label: '所属机构',
          prop: 'orgId',
          type: 'select',
          dictUrl: '/organization/_all',
          props: { label: 'name', value: 'id' },
          rowShow: false,
          sortable: true,
          searchShow: false,
        }, {
          label: '存储策略',
          prop: 'storePolicy',
          type: 'select',
          placeholder: '请选择存储策略，默认ES-列式存储',
          // dictUrl: 'device/product/storage/policies',
          dictData: this.$x.biz.StorageStrategy.DictData,
          props: { label: 'name', value: 'id' },
          rowShow: false,
          sortable: true,
          searchShow: false,
        }, {
          label: '接入协议',
          prop: 'messageProtocol',
          type: 'select',
          dictUrl: '/protocol/supports',
          props: { label: 'name', value: 'id' },
          sortable: true,
          rules: {
            rule: 'required',
          },
          searchShow: false,
        }, {
          label: '连接协议',
          prop: 'transportProtocol',
          type: 'select',
          dictData: [{ id: 'TCP', name: 'TCP' }, { id: 'UDP', name: 'UDP' }, { id: 'MQTT', name: 'MQTT' }, { id: 'HTTP', name: 'HTTP' }, { id: 'CoAP', name: 'CoAP' }, { id: 'WebSocket', name: 'WebSocket' }],
          props: { label: 'name', value: 'id' },
          sortable: true,
          rowSelect: true,
          selectVariant: () => 'light-primary',
          rules: {
            rule: 'required',
          },
          searchShow: false,
        }, {
          label: '描述',
          labelShow: true,
          prop: 'description',
          type: 'textarea',
          rows: 5,
          searchShow: false,
          rowShow: false,
        },
        ],
      },
    }
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowEdit(data, done) {
      this.$delete(data, 'count')
      edit(data).then(() => {
        done()
      })
    },
    rowPublish(data, done) {
      deploy(data.id).then(() => {
        done()
      })
    },
    rowDisable(data, done) {
      unDeploy(data.id).then(() => {
        done()
      })
    },
    dataPreHandle(data, done) {
      count(data.id).then(resp => {
        data.count = resp.data.result
        done(data)
      })
    },
    rowDownload(data) {
      // 创建隐藏的可下载链接
      const eleLink = document.createElement('a')
      eleLink.download = `产品-${data.name || new Date().format('YYYY/MM/DD HH:mm:ss')}.json`
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      const blob = new Blob([JSON.stringify(data)])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    rowDelete(data, done) {
      // if (!data.count) {
      //   this.$xtoast.error('设备数量未同步，无法删除！')
      //   return
      // }
      if (data.count && data.count > 0) {
        this.$xtoast.error('已绑定产品的设备，无法删除！')
        return
      }
      remove(data.id).then(() => {
        done()
      })
    },
    addHandle(formData, done) {
      formData.deviceType = this.$x.biz.DeviceType.DEVICE
      add(formData).then(() => {
        done()
      })
    },
    // 上传图片
    uploadSuccess(response, row) {
      if (response.code === 'success') {
        this.$xtoast.success('上传成功')
        row.photoUrl = response.result
        this.photoUrl = response.result
      }
    },
    beforeShow(type, data) {
      if (type.isEdit) {
        this.editRow = data
        this.photoUrl = data.photoUrl
      }
    },
    restModal() {
      this.dialogVisible = false
    },
    handleRemove(row) {
      this.fileList = []
      this.photoUrl = undefined
      row.photoUrl = ''
    },
    onExceed() {
      this.$xtoast.warning('头像仅可上传一张，请删除后重新上传！')
    },
    handlePictureCardPreview(row) {
      if (row.photoUrl) {
        this.dialogImageUrl = row.photoUrl
        this.dialogVisible = true
      }
    },
  },

}
</script>

<style lang="scss">
.el-upload--picture-card {
  border: none !important;
  text-align: left;
  padding: 0px !important;
  margin: 0px !important;
  line-height: 30px !important;
}
.upload-cx:hover:after{
  position:absolute;
  display: block;
  width:100%;
  height: 100%;
  border-radius: 50%;
  // background-color: rgba(0, 0, 0, 0.5);
  background: rgba(0, 0, 0, 0.5) url("../../../system/usercenter/utils/browse.png") no-repeat center center;
  background-size: 30px;
  content: attr(data-text);
  transition:all 3s ease;
  color: #FFF;
  text-align: center !important;
  vertical-align: middle !important;
  cursor: pointer;
}
</style>
